// VolumeBots.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, Modal, Table, Card, InputGroup } from 'react-bootstrap';
import { ethers } from 'ethers';

function VolumeBots({ wallets, tokens, fetchTokens, logs, setLogs, provider }) {
  // State variables specific to Volume Bots
  const [buyAmount, setBuyAmount] = useState('0.0001');
  const [sellAmount, setSellAmount] = useState('10000000');
  const [delay, setDelay] = useState(15000);
  const [performBuy, setPerformBuy] = useState(true);
  const [performSell, setPerformSell] = useState(false);
  const [ethBalance, setEthBalance] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [bots, setBots] = useState([]);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [botLogs, setBotLogs] = useState([]);
  const [showAddTokenModal, setShowAddTokenModal] = useState(false);
  const [tokenNameInput, setTokenNameInput] = useState('');
  const [tokenAddressInput, setTokenAddressInput] = useState('');

  // Fetch bots on mount
  useEffect(() => {
    fetchBots();
  }, []);

  useEffect(() => {
    if (wallets.length > 0 && !selectedWalletId) {
      setSelectedWalletId(wallets[0].id);
    }
  }, [wallets]);

  useEffect(() => {
    if (tokens.length > 0 && !selectedTokenId) {
      setSelectedTokenId(tokens[0].id);
    }
  }, [tokens]);

  useEffect(() => {
    const wallet = wallets.find((w) => w.id == selectedWalletId);
    setSelectedWallet(wallet);
    fetchBalances();
  }, [selectedWalletId, selectedTokenId]);

  useEffect(() => {
    let logsInterval;
    if (selectedBotId) {
      fetchBotLogs(selectedBotId);
      logsInterval = setInterval(() => {
        fetchBotLogs(selectedBotId);
      }, 5000); // Refresh every 5 seconds
    }
    return () => {
      if (logsInterval) clearInterval(logsInterval);
    };
  }, [selectedBotId]);

  const fetchBots = async () => {
    try {
      const response = await fetch('https://bot-backend.qvrse.io/api/bots');
      const data = await response.json();
      setBots(data);
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching bots: ${error.message}`, ...prevLogs]);
    }
  };

  const fetchBalances = async () => {
    try {
      const wallet = wallets.find((w) => w.id == selectedWalletId);
      const token = tokens.find((t) => t.id == selectedTokenId);
      if (!wallet || !token) return;

      const address = wallet.address;
      const tokenAddress = token.address;

      // Fetch ETH balance
      const balance = await provider.getBalance(address);
      setEthBalance(ethers.utils.formatEther(balance));

      // Fetch token balance
      const tokenContract = new ethers.Contract(
        tokenAddress,
        [
          'function balanceOf(address owner) view returns (uint256)',
          'function decimals() view returns (uint8)',
        ],
        provider
      );

      const decimals = await tokenContract.decimals();
      const rawTokenBalance = await tokenContract.balanceOf(address);
      const formattedTokenBalance = ethers.utils.formatUnits(rawTokenBalance, decimals);
      setTokenBalance(formattedTokenBalance);
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching balances: ${error.message}`, ...prevLogs]);
    }
  };

  // Handle adding a new token
  const handleAddToken = async () => {
    if (!tokenNameInput || !tokenAddressInput) {
      setLogs((prevLogs) => [`Please provide both token name and address.`, ...prevLogs]);
      return;
    }
    try {
      const response = await fetch("https://bot-backend.qvrse.io/api/tokens", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: tokenNameInput,
          address: tokenAddressInput,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`Token added: ${tokenNameInput}`, ...prevLogs]);
        setTokenNameInput("");
        setTokenAddressInput("");
        setShowAddTokenModal(false);
        fetchTokens();
      } else {
        setLogs((prevLogs) => [`Error adding token: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error adding token: ${error.message}`, ...prevLogs]);
    }
  };

  // Handle starting a bot
  const handleStartBot = async () => {
    if (!selectedWalletId || !selectedTokenId || !delay || (!performBuy && !performSell)) {
      setLogs((prevLogs) => [`Please fill in all required fields.`, ...prevLogs]);
      return;
    }
    try {
      const response = await fetch("https://bot-backend.qvrse.io/api/bots", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          walletId: selectedWalletId,
          tokenId: selectedTokenId,
          buyAmount,
          sellAmount,
          delay,
          performBuy,
          performSell,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`Bot started with ID: ${data.botId}`, ...prevLogs]);
        fetchBots();
      } else {
        setLogs((prevLogs) => [`Error starting bot: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error starting bot: ${error.message}`, ...prevLogs]);
    }
  };

  // Handle stopping a bot
  const handleStopBot = async (botId) => {
    try {
      const response = await fetch(`https://bot-backend.qvrse.io/api/bots/${botId}/stop`, {
        method: "POST",
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`Bot ${botId} stopped`, ...prevLogs]);
        fetchBots();
      } else {
        setLogs((prevLogs) => [`Error stopping bot: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error stopping bot: ${error.message}`, ...prevLogs]);
    }
  };

  const handleDeleteBot = async (botId) => {
    if (window.confirm("Are you sure you want to delete this bot?")) {
      try {
        const response = await fetch(`https://bot-backend.qvrse.io/api/bots/${botId}`, {
          method: "DELETE",
        });
        const data = await response.json();
        if (response.ok) {
          setLogs((prevLogs) => [`Bot ${botId} deleted`, ...prevLogs]);
          fetchBots();
        } else {
          setLogs((prevLogs) => [`Error deleting bot: ${data.error}`, ...prevLogs]);
        }
      } catch (error) {
        setLogs((prevLogs) => [`Error deleting bot: ${error.message}`, ...prevLogs]);
      }
    }
  };

  const handleViewLogs = (botId) => {
    setSelectedBotId(botId);
    fetchBotLogs(botId);
  };

  const fetchBotLogs = async (botId) => {
    try {
      const response = await fetch(`https://bot-backend.qvrse.io/api/bots/${botId}/logs`);
      const data = await response.json();
      if (response.ok) {
        setBotLogs(data);
      } else {
        setLogs((prevLogs) => [`Error fetching logs: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching logs: ${error.message}`, ...prevLogs]);
    }
  };

  const handleCopyAddress = () => {
    if (selectedWallet) {
      navigator.clipboard
        .writeText(selectedWallet.address)
        .then(() => {
          setLogs((prevLogs) => [`Address copied to clipboard`, ...prevLogs]);
        })
        .catch((error) => {
          setLogs((prevLogs) => [`Failed to copy address: ${error.message}`, ...prevLogs]);
        });
    }
  };

  const handleViewOnBaseScan = () => {
    if (selectedWallet) {
      const baseScanUrl = `https://basescan.org/address/${selectedWallet.address}`;
      window.open(baseScanUrl, "_blank");
    }
  };

  return (
    <Container fluid>
      {/* Modals */}
      {/* Add Token Modal */}
      <Modal show={showAddTokenModal} onHide={() => setShowAddTokenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Token Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter token name"
                value={tokenNameInput}
                onChange={(e) => setTokenNameInput(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Token Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter token address"
                value={tokenAddressInput}
                onChange={(e) => setTokenAddressInput(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddTokenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddToken}>
            Add Token
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Main Content */}
      <Row className="mt-5">
        {/* Inputs Section */}
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="">
                <h3>Uniswap Volume Bot</h3>
              </Card.Title>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Select Wallet</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedWalletId || ""}
                    onChange={(e) => {
                      setSelectedWalletId(e.target.value);
                      const wallet = wallets.find((w) => w.id == e.target.value);
                      setSelectedWallet(wallet);
                    }}
                  >
                    <option value="" disabled>
                      Select a wallet
                    </option>
                    {wallets.map((wallet) => (
                      <option key={wallet.id} value={wallet.id}>
                        {wallet.name} ({wallet.address})
                      </option>
                    ))}
                  </Form.Control>
                  {/* You can add Add Wallet button here if needed */}
                </Form.Group>

                {/* Display Balances */}
                <div className="mt-4">
                  <h3>Balances:</h3>
                  {selectedWallet && (
                    <Form.Group className="mb-3">
                      <Form.Label>Wallet Address:</Form.Label>
                      <InputGroup>
                        <Form.Control type="text" value={selectedWallet.address} readOnly />
                        <Button variant="secondary" onClick={handleCopyAddress}>
                          Copy Address
                        </Button>
                        <Button variant="primary" onClick={handleViewOnBaseScan}>
                          View on BaseScan
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  )}

                  <p>ETH Balance: {ethBalance}</p>
                  <p>Token Balance: {tokenBalance}</p>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Select Token</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTokenId || ""}
                    onChange={(e) => setSelectedTokenId(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a token
                    </option>
                    {tokens.map((token) => (
                      <option key={token.id} value={token.id}>
                        {token.name} ({token.address})
                      </option>
                    ))}
                  </Form.Control>
                  <Button variant="link" onClick={() => setShowAddTokenModal(true)}>
                    Add Token
                  </Button>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Buy Amount</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.0001"
                    placeholder="Enter buy amount"
                    value={buyAmount}
                    onChange={(e) => setBuyAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sell Amount</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.0001"
                    placeholder="Enter sell amount"
                    value={sellAmount}
                    onChange={(e) => setSellAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Delay between trades (ms)</Form.Label>
                  <Form.Control
                    type="number"
                    step="1000"
                    placeholder="Enter delay in milliseconds"
                    value={delay}
                    onChange={(e) => setDelay(Number(e.target.value))}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Perform Buy"
                    checked={performBuy}
                    onChange={(e) => setPerformBuy(e.target.checked)}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Perform Sell"
                    checked={performSell}
                    onChange={(e) => setPerformSell(e.target.checked)}
                  />
                </Form.Group>
                <Button variant="success" onClick={handleStartBot}>
                  Start Bot
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Bots and Logs Section */}
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="text-center">Running Bots</Card.Title>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Wallet</th>
                    <th>Token</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bots.map((bot) => {
                    const wallet = wallets.find((w) => w.id == bot.wallet_id);
                    const token = tokens.find((t) => t.id == bot.token_id);
                    return (
                      <tr key={bot.id}>
                        <td>{bot.id}</td>
                        <td>{wallet ? `${wallet.name} (${wallet.address})` : "Unknown Wallet"}</td>
                        <td>{token ? token.name : "Unknown Token"}</td>
                        <td>{bot.status}</td>
                        <td>
                          {bot.status === "running" ? (
                            <Button variant="danger" size="sm" onClick={() => handleStopBot(bot.id)}>
                              Stop
                            </Button>
                          ) : (
                            "Stopped"
                          )}{" "}
                          <Button variant="info" size="sm" onClick={() => handleViewLogs(bot.id)}>
                            View Logs
                          </Button>{" "}
                          <Button variant="danger" size="sm" onClick={() => handleDeleteBot(bot.id)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className="text-center">Logs</Card.Title>
              {selectedBotId ? (
                <div>
                  <p>Showing logs for Bot ID: {selectedBotId}</p>
                  <Button variant="secondary" size="sm" onClick={() => setSelectedBotId(null)}>
                    Back to All Bots
                  </Button>
                  <div
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      marginTop: "10px",
                    }}
                  >
                    {botLogs.map((log, index) => (
                      <Alert key={index} variant="dark">
                        [{new Date(log.timestamp).toLocaleString()}] {log.message}
                      </Alert>
                    ))}
                  </div>
                </div>
              ) : (
                <p>Select a bot to view logs.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default VolumeBots;
