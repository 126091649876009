// Docs.js

import React from 'react';
import { Container, Card } from 'react-bootstrap';

function Docs() {
  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title className="text-center">Bot Usage Documentation</Card.Title>
          <div style={{ maxHeight: '80vh', overflowY: 'auto', padding: '1rem' }}>
            {/* Tutorials Content */}
            <h2>Introduction</h2>
            <p>
              Welcome to the Bot Dashboard! This documentation will guide you through the process of using both the Volume Bots and AI Bots available in the application.
            </p>

            {/* Volume Bots Tutorial */}
            <h2>Volume Bots</h2>
            <h3>What are Volume Bots?</h3>
            <p>
              Volume Bots are automated tools designed to perform buy and sell operations on Uniswap to simulate trading volume for specific tokens. This can help with market making and liquidity provision.
            </p>

            <h3>How to Use Volume Bots</h3>
            <ol>
              <li>
                <strong>Add a Wallet</strong>
                <ul>
                  <li>Click on the <strong>"Add Wallet"</strong> button.</li>
                  <li>Enter a <strong>Wallet Name</strong> and <strong>Private Key</strong>.</li>
                  <li>Ensure the wallet has sufficient ETH for gas fees.</li>
                </ul>
              </li>
              <li>
                <strong>Add a Token</strong>
                <ul>
                  <li>Click on <strong>"Add Token"</strong> if your token is not listed.</li>
                  <li>Enter the <strong>Token Name</strong> and <strong>Token Address</strong>.</li>
                </ul>
              </li>
              <li>
                <strong>Configure the Bot</strong>
                <ul>
                  <li>Select the desired <strong>Wallet</strong> and <strong>Token</strong>.</li>
                  <li>Set the <strong>Buy Amount</strong> and <strong>Sell Amount</strong>.</li>
                  <li>Specify the <strong>Delay Between Trades</strong>.</li>
                  <li>Choose whether to <strong>Perform Buy</strong>, <strong>Perform Sell</strong>, or both.</li>
                </ul>
              </li>
              <li>
                <strong>Start the Bot</strong>
                <ul>
                  <li>Click on the <strong>"Start Bot"</strong> button.</li>
                </ul>
              </li>
              <li>
                <strong>Monitor and Manage Bots</strong>
                <ul>
                  <li>View active bots in the <strong>"Running Bots"</strong> section.</li>
                  <li>Stop or delete bots as needed.</li>
                  <li>View logs to monitor bot activities.</li>
                </ul>
              </li>
            </ol>

            <h3>Important Notes</h3>
            <ul>
              <li>Ensure your wallet has enough ETH for gas fees.</li>
              <li>Be cautious with trading amounts to manage risk.</li>
              <li>Check legal compliance regarding simulated trading volume.</li>
            </ul>

            {/* AI Bots Tutorial */}
            <h2>AI Bots</h2>
            <h3>What are AI Bots?</h3>
            <p>
              AI Bots are automated Telegram bots powered by OpenAI's GPT models. They can post messages at set intervals or respond to user messages based on a defined personality.
            </p>

            <h3>How to Use AI Bots</h3>
            <ol>
              <li>
                <strong>Prepare Telegram Bot Credentials</strong>
                <ul>
                  <li>Create a bot using <strong>@BotFather</strong> in Telegram.</li>
                  <li>Obtain the <strong>Telegram Bot Token</strong>.</li>
                  <li>Find the <strong>Telegram Chat ID</strong> where the bot will operate.</li>
                </ul>
              </li>
              <li>
                <strong>Add an AI Bot in the Dashboard</strong>
                <ul>
                  <li>Navigate to the <strong>"AI Bots"</strong> section.</li>
                  <li>Click on <strong>"Add AI Bot"</strong>.</li>
                  <li>Fill in the required details, including the bot type.</li>
                </ul>
              </li>
              <li>
                <strong>Start the Bot</strong>
                <ul>
                  <li>Click on the <strong>"Start"</strong> button next to your bot.</li>
                </ul>
              </li>
              <li>
                <strong>Interact with the Bot</strong>
                <ul>
                  <li>For <strong>Time Delay Bots</strong>, the bot will post messages at intervals.</li>
                  <li>For <strong>Response Bots</strong>, send messages to the bot to receive responses.</li>
                </ul>
              </li>
              <li>
                <strong>Manage the Bot</strong>
                <ul>
                  <li>Stop or delete the bot as needed.</li>
                  <li>poll:What is your favorite color?|Red|Blue|Green</li>
                  <li>meme: make a crypto degen moon meme</li>
                </ul>
              </li>
            </ol>

            <h3>Important Notes</h3>
            <ul>
              <li>Ensure your OpenAI API key is configured on the server.</li>
              <li>Add the bot to the Telegram chat and grant necessary permissions.</li>
              <li>Use unique bot tokens for each AI bot to avoid conflicts.</li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Docs;
